import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import '../css/all.scss'
import '../css/customize.scss'
import '../css/style.scss'

import Config from '../config'
import Footer from '../components/footer'

const Layout = ({ children }) => (
  <>
    <Helmet
      title={Config.header.title}
      meta={Config.header.meta}
    >
      <html lang={Config.html.lang} />
    </Helmet>

    <div className="site-content">{children}</div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
