import React from 'react'
import Layout from '../layout/index'

import '../css/index/style.scss'
import "animate.css/animate.min.css";
import Icon1 from '../images/index/icon1.jpg'
import Icon2 from '../images/index/icon2.jpg'
import Icon3 from '../images/index/icon3.jpg'
import guideIconPath from '../images/index/guide_icon.png'

import Config from '../config'
import Menu from '../modules/Menu'
import ScrollAnimation from 'react-animate-on-scroll';

const IndexPage = () => (
  <Layout>
    <ScrollAnimation animateIn="fadeIn"
      duration={3}
      animateOnce={true}>

    <section className="index"
      onLoad={() => { window.scroll( 0, 1 ); }}>

      <article className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <ScrollAnimation animateIn="fadeIn"
              duration={1.5}
              delay={800}
              animateOnce={true}>
              <div className="shadow"></div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInDown"
              duration={1.5}
              animateOnce={true}
              scrollableParentSelector='section.index'>
              <div className="board">
                <div className="contents">
                  <span>我々は、</span>
    	            <h1 className="title">株式会社gleanbean</h1>

    	            <p className="message">
                    使命は、
                    <span className="marked-by-coveredline marker-is-yellow">あなた</span>
                    のビジネスを<br/>
                    <span className="marked-by-underline marker-is-red">ＩＴ</span>
                    でより輝かせること。
                  </p>
    	          </div>
                <img src={guideIconPath} id="guide" alt="guide"/>
    	        </div>
            </ScrollAnimation>
          </div>
        </div>
      </article>

      <article className="main-contents">
        <div className="container columns is-multiline">

          <div className="column is-4">
            <ScrollAnimation animateIn="fadeIn"
              delay={100}
              animateOnce={true}>
              <div className="card">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-96x96">
                        <img src={Icon1}
                          alt="ロボット"
                          className="is-rounded"/>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title">
                        <span className="marked-by-underline marker-is-yellow">その作業、ロボットに任せませんか？</span>
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <p>
                      繰り返し行う作業、面倒ですよね。
                    </p>
                    <p>
                      その作業がロボットに任せられたらいいと思いませんか？
                      我々がお手伝いできるかもしれません。
                    </p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="column is-4">
            <ScrollAnimation animateIn="fadeIn"
              delay={300}
              animateOnce={true}>
              <div className="card">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-96x96">
                        <img src={Icon2}
                          alt="ヒト・モノ・カネ"
                          className="is-rounded"/>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title">
                        <span className="marked-by-underline marker-is-yellow">管理、育成。お手伝いします。</span>
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <p>
                      事業に必要なヒト・モノ・カネ。
                      とはいえ、その管理、育成は簡単ではありません。
                    </p>
                    <p>
                      ＩＴ活用で、これをお手伝いします。
                    </p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="column is-4">
            <ScrollAnimation animateIn="fadeIn"
              delay={500}
              animateOnce={true}>
              <div className="card">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-96x96">
                        <img src={Icon3}
                          alt="業務ツール"
                          className="is-rounded"/>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title">
                        <span className="marked-by-underline marker-is-yellow">ツールで時間短縮しませんか？</span>
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <p>
                      業務でよく使う&nbsp;Excel、Webサイト。
                      「こうだったら、もっと効率上がるのに」
                      って、思ったことありませんか？
                    </p>
                    <p>
                      実はそれ、できるかもしれません。
                    </p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="column is-6 is-offset-3 has-text-centered">
            <ScrollAnimation animateIn="fadeIn"
              delay={100}
              animateOnce={true}>
              <p>
                これらのことにピンッ！ときたら、まずはご相談ください。
                <a href={`mailto:${Config.mail.sesDivision}`}>{Config.mail.sesDivision}</a>
              </p>
            </ScrollAnimation>
          </div>

          <div className="appendix column is-6 is-offset-3 has-text-centered">
            <p>
              より詳細な情報はこちら
            </p>
            <ul dangerouslySetInnerHTML={{
              __html: Menu.createAsLiLinks( Config.menu )
            }}/>
          </div>
        </div>
      </article>

    </section>

    </ScrollAnimation>
  </Layout>
);
export default IndexPage;
